<template>
  <Toast position="bottom-right" />
  <DataTable
    :value="items"
    dataKey="ticker"
    class="p-datatable-sm mb-5"
    stripedRows
    ref="datatable"
    :loading="ocupado"
    @rowExpand="onRowExpand"
    v-model:filters="filter"
    v-model:expandedRows="expandedRows"
    :globalFilterFields="['ticker', 'cotacao', 'tags']"
  >
    <Column :expander="true" headerStyle="width: 3rem" />
    <Column field="ticker" header="Ticker" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ data.ticker }}
        </div>
      </template>
    </Column>
    <Column field="cotacao" header="Cotação" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatCurrency(data.cotacao) }}
        </div>
      </template>
    </Column>

    <Column field="pvp" header="P/VP" :sortable="true">
      <template #body="{ data }">
        <div class="text-center small" :class="PvpBackColor(data.pvp)">
          {{ $formatPercent(data.pvp) }}
        </div>
      </template>
    </Column>

    <Column field="valorPatrimonial" header="VP">
      <template #body="{ data }">
        <div class="text-center small">
          {{ $formatCurrency(data.valorPatrimonial) }}
        </div>
      </template>
    </Column>

    <Column field="dy1" header="Últ. Rend" :sortable="true">
      <template #body="{ data }">
        <div class="text-center hand small" @click="AbrirModalDividendos(data.ticker)">
          {{ $formatCurrency(data.ultimoRendimento) }} | {{ $formatPercent(data.dy1) }} %
        </div>
      </template>
    </Column>

    <Column field="dataComUltimoRendimento" header="Data-Com" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatDate(data.dataComUltimoRendimento) }}
        </div>
      </template>
    </Column>

    <Column field="dy12" header="DY 12 Meses" :sortable="true">
      <template #body="{ data }">
        <div
          class="text-center hand"
          :class="Dy12Backcolor(data.dy12)"
          @click="AbrirModalDividendos(data.ticker)"
        >
          {{ $formatPercent(data.dy12) }} %
        </div>
      </template>
    </Column>

    <Column field="tags" header="Tags">
      <template #body="{ data }">
        <div class="text-center small">
          <span
            class="badge badge-tags me-1"
            v-for="tag in SplitTags(data.tags)"
            :key="tag"
          >
            {{ tag }}
          </span>
        </div>
      </template>
    </Column>

    <Column field="patrimonio" header="Patrimônio" :sortable="true">
      <template #body="{ data }">
        <div class="text-center small">
          {{ CalcularPatrimonio(data.patrimonio) }}
        </div>
      </template>
    </Column>

    <template #expansion="props">
      <div class="orders-subtable">
        <h5 class="mb-2">{{ props.data.ticker }}</h5>
        <p class="mb-1">{{ Milhar(props.data.cotistas) }} Cotistas</p>
        <p>{{ $formatDateTime(props.data.lastUpdated) }} Atualizado</p>
        <Button
          @click="AbrirModalDividendos(props.data.ticker)"
          label="Dividendos"
          icon="pi pi-check"
          class="p-button-raised p-button-text p-button-plain p-button-sm"
        />
        <Button
          @click="AdicionarNaMinhaLista(props.data.ticker)"
          label="Minha Lista"
          title="Adicionar a Minha Lista"
          icon="pi pi-plus"
          class="p-button-raised p-button-text p-button-plain p-button-sm"
        />
        <Button
          @click="AbrirInfo(props.data.ticker)"
          label="Info do FII"
          title="Adicionar a Minha Lista"
          icon="pi pi-plus"
          class="p-button-raised p-button-text p-button-plain p-button-sm"
        />
      </div>
    </template>
    <template #footer> Os FIIs listados nessa tabela compõem o IFIX. </template>

    <template #empty>
      <div class="p-5 text-center bd-highlight">
        <div class="alert alert-warning" role="alert" v-if="!online">
          OCORREU UM ERRO AO OBTER AS INFORMAÇÕES
        </div>
        <div v-else>
          <p class="fs-5" style="text-transform: uppercase">
            Nenhuma informação encontrada
          </p>
        </div>
      </div>
    </template>
  </DataTable>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Button from "primevue/button";
import RankingService from "@/services/RankingService.js";
import { computed } from "vue";
import { useMainStore } from "@/store/index.js";
import { FilterMatchMode } from "primevue/api";
import { useRouter } from "vue-router";
import {
  Dy12Backcolor,
  PvpBackColor,
  SplitTags,
  CalcularPatrimonio,
  Milhar,
} from "@/utils/CalcFiis";
import { useToast } from "primevue/usetoast";

const store = useMainStore();
const router = useRouter();
const ocupado = ref(true);
const ready = ref(false);
const service = ref();
const datatable = ref();

const toast = useToast();

const items = computed(() => {
  return store.fiis;
});

const online = computed(() => {
  return store.online;
});

const filter = computed(() => {
  return {
    global: {
      value: store.filter,
      matchMode: FilterMatchMode.CONTAINS,
    },
  };
});

const expandedRows = ref([]);

onMounted(async () => {
  try {
    service.value = new RankingService();
    await service.value.getRanking();
  } catch (error) {
    console.log(error);
  } finally {
    ocupado.value = false;
    ready.value = true;
  }
});

const AbrirModalDividendos = (item) => {
  store.AbrirModalDividendos(item);
};

const onRowExpand = (event) => {
  console.log(event);
};

function AdicionarNaMinhaLista(symbol) {
  store.AtualizarMinhaLista(symbol);
  toast.add({
    severity: "info",
    summary: "Minha Lista",
    detail: `${symbol} adicionado`,
    life: 3000,
  });
}

function AbrirInfo(symbol) {
  router.push({ name: "Info", params: { id: symbol } });
}
</script>

<style>
span.badge-tags {
  color: black;
  background-color: lightgray;
}
.orders-subtable {
  padding: 1rem;
}
</style>
