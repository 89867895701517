<template>
  <div class="container content">
    <TabView @tab-change="tabChange" :activeIndex="state.activeTab">
      <TabPanel :header="headerCotacoes">
        <TabelaFiis></TabelaFiis>
      </TabPanel>
      <TabPanel :header="headerMinhaLista">
        <MinhaLista></MinhaLista>
      </TabPanel>      
    </TabView>
    <Subscribed></Subscribed>
  </div>
</template>

<script setup>
import { onMounted, reactive, computed } from 'vue'
import TabelaFiis from "@/components/TabelaFiis.vue";
import Subscribed from "@/components/Subscribed.vue";
import MinhaLista from "@/components/MinhaLista.vue";
import { useMainStore } from '@/store/index.js'

const state = reactive({
  activeTab: 0
});

const store = useMainStore();

const headerMinhaLista = computed(() => {
  return `Minha Lista (${store.minhaLista.length})`;
});

const headerCotacoes = computed(() => {
  return `Cotações (${store.fiis.length})`;
});

onMounted(() => {
  let tab = localStorage.getItem('activeTab') ?? 0;
  state.activeTab = Number(tab);
})

function tabChange (tab) {
  localStorage.setItem('activeTab', tab.index)
}
</script>
