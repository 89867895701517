<template>
  <div class="container content">
    <Card style="margin-bottom: 2em" v-if="state.info">
      <template #title
        >{{ state.info.name }}
        <Tag
          :value="item"
          v-for="(item, index) in state.info.symbols"
          severity="success"
          :key="index"
          class="me-2"
        ></Tag>
      </template>
      <template #content>
        <Tag
          :value="item"
          v-for="(item, index) in state.info.sectors"
          :key="index"
          class="me-2 mb-2"
        ></Tag>
      </template>
    </Card>
    <Card style="margin-bottom: 2em" v-if="state.info">
      <template #content>
        <DataView
          :value="state.info.properties"
          :layout="layout"
          :paginator="false"
          :rows="7"
          v-if="state.info && state.info.properties"
        >
          <template #grid="slotProps">
            <div class="col-12 md:col-4">
              <div class="grid-item card">
                <div class="grid-item-top">
                  <div>
                    <i class="pi pi-tag"></i>
                    <span class="property-description">{{ slotProps.data.name }}</span>
                  </div>
                  <Tag :value="slotProps.data.state"></Tag>
                </div>
                <div>{{ slotProps.data.address }} M2</div>
                <div class="property-m2">{{ slotProps.data.sizeM2 }} M2</div>
              </div>
            </div>
          </template>
        </DataView>
      </template>
    </Card>
    <TabelaDividendos
      :dividends="dividends"
      :ocupado="state.ocupado"
      :maxDividendo="state.maxDiv"
    >
    </TabelaDividendos>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import TradeService from "@/services/TradeService";
import TabelaDividendos from "@/components/TabelaDividendos.vue";
import Tag from "primevue/tag";
import DataView from "primevue/dataview";

const state = reactive({
  servico: null,
  info: null,
  dividends: null,
  maxDividendo: 0,
  ocupado: true,
  symbol: null,
});

const layout = ref("grid");

const router = useRouter();

onMounted(async () => {
  state.ocupado = false;
  state.symbol = router.currentRoute.value.params.id;
  state.servico = new TradeService();

  GetDividends();
  await GetInfo();
});

async function GetInfo() {
  try {
    state.info = await state.servico.getInfo(state.symbol);
  } catch (error) {
    console.log(error);
  }
}

async function GetDividends() {
  var response = await state.servico.getDividends(state.symbol);
  let tmp = response.data;

  var values = tmp.map((item) => {
    return Number(item.value);
  });
  state.maxDividendo = Math.max.apply(Math, values);
  state.dividends = response.data;
}
</script>

<style lang="scss" scoped>
.grid-item-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.property-description {
  margin: 0 0 1rem 0;
  font-weight: 600;
}

.property-m2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

.pi {
  vertical-align: middle;
  margin-right: 0.5rem;
}

::v-deep(.grid-item) {
  margin: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--surface-border);
}
</style>
