import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import http from './http.js'
import { createPinia } from 'pinia'

const pinia = createPinia()

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css' 
import 'primeicons/primeicons.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import '@/styles/custom.scss'

import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PrimeVue from "primevue/config";
import Button from 'primevue/button';
import Toast from 'primevue/toast';

import ToastService from 'primevue/toastservice';

import { FormatCurrency, FormatDate, FormatDateTime, FormatPercent } from '@/utils/format.js'

const app = createApp(App)

app.config.globalProperties.$http = http
app.config.globalProperties.$formatCurrency = FormatCurrency
app.config.globalProperties.$formatDate = FormatDate
app.config.globalProperties.$formatDateTime = FormatDateTime
app.config.globalProperties.$formatPercent = FormatPercent

app.component('Button', Button)
app.component('Card', Card)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('TabPanel', TabPanel)
app.component('TabView', TabView)
app.component('Toast', Toast)

app.use(pinia)
app.use(PrimeVue)
app.use(router)
app.use(ToastService)

app.mount('#app')
