<template>
  <footer class="footer-59391 fixed-bottom">
    <div class="row px-2">
      <div class="col-2 d-none d-lg-block"></div>
      <div class="col text-center">    
        <ul class="nav-links list-unstyled nav-left">
          <li>
            <a href="#" @click="AbrirModalColabore">Fale Conosco</a>
          </li>
        </ul>
      </div>      
      <div class="col-2 d-none d-lg-block"></div>
    </div>
    <div class="row">
      <div class="copyright d-none d-md-block">
        <small>Copyright © 2021. RankingFii - Todos os direitos reservados</small>
      </div>
    </div>
  </footer>
  <Dialog 
    v-model:visible="state.displayBasic"
    :modal="true"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">    
    <template #header>
      <h5>Rankingfii.com.br</h5>
    </template>
    <div class="d-block text-center p-2">
      <p class="mb-1" style="font-size: 1rem">Desenvolvido por Carlos Alexandre Laranjeiras</p>
      <p class="mb-1">+55(21)9977-06037</p>
      <p class="mb-1">all.devops@hotmail.com</p>
    </div>
    <div class="d-block text-center p-2">
      <p style="font-weight: 600;">Ajude-nos a manter este projeto funcionando gratuitamente para todos</p>
      <p>Chave PIX Celular: (21)997706037</p>
      
      <img src='@/assets/QrCode.png'/>
      <p class="mb-1">CARLOS A A LARANJEIRAS</p>
      <p>***.849.047-**</p>
    </div>
  </Dialog>  
</template>

<script setup>
import { reactive } from 'vue';
import Dialog from 'primevue/dialog';

const state = reactive({
  displayBasic: false
})

function AbrirModalColabore () {
  state.displayBasic = true;
}
</script>

<style style="scss">
footer {
  border-top: 1px solid #efefef;
  background-color: whitesmoke;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 0 0 0;
  margin-top: 2rem;
}

.footer-59391 .nav-links li {
  display: inline-block;
}

.footer-59391 .nav-links li a {
  font-size: 14px;
  color: #777;
}

@media (max-width: 1199.98px) {
  .footer-59391 .nav-links.nav-left li:first-child a {
    padding-left: 0;
  }
}

.footer-59391 .nav-links.nav-right li:last-child a {
  padding-right: 0;
}

@media (max-width: 1199.98px) {
  .footer-59391 .nav-links.nav-right li:first-child a {
    padding-left: 0;
  }
}

.footer-59391 .copyright {
  border-top: 1px solid #efefef;
  padding: 5px;
  text-align: center;
  color: #ffd700;
  background-color: #4d4a49;
}
</style>