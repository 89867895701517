<template>
  <Header></Header>
  <router-view />
  <Footer></Footer>

  <ModalDividendos
    v-bind:ticker="tickerSelecionado"
    v-if="exibirModalDividendos && tickerSelecionado"
  />
</template>

<script setup>
import { computed } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import ModalDividendos from "@/components/ModalDividendos";
import { useMainStore } from "@/store/index.js";

const store = useMainStore();

const exibirModalDividendos = computed(() => {
  return store.exibirModalDividendos;
});

const tickerSelecionado = computed(() => {
  return store.tickerSelecionado;
});
</script>
