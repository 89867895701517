<template>
  <Dialog
    ref="dg"
    :header="title"
    v-model:visible="display"
    @hide="onHide"
    :maximizable="true"
    :modal="true"
  >
    <TabelaDividendos
      :dividends="dividends"
      :ocupado="state.ocupado"
      :maxDividendo="state.maxDiv"
    >
    </TabelaDividendos>
    <template #footer>
      <Button
        label="VOLTAR"
        icon="pi pi-check"
        class="p-button-sm"
        @click="Close"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, computed, reactive } from "vue";
import TradeService from "@/services/TradeService.js";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { useMainStore } from "@/store/index.js";
import TabelaDividendos from "@/components/TabelaDividendos.vue";

const props = defineProps(["ticker"]);

const display = ref(true);
const ticker = ref(props.ticker);
const dg = ref();
const service = ref();
const dividends = ref();
const store = useMainStore();
const state = reactive({});

const title = computed(() => {
  return `Dividendos de ${ticker.value}`;
});

onMounted(async () => {
  service.value = new TradeService();
  try {
    var response = await service.value.getDividends(ticker.value);
    let tmp = response.data;

    var values = tmp.map((item) => {
      return Number(item.value);
    });
    state.maxDiv = Math.max.apply(Math, values);
    dividends.value = response.data;
  } catch (error) {
    alert("Ocorreu um erro ao carregar os dividendos");
    console.log(error);
  }
});

const onHide = () => {
  Close();
};

const Close = () => {
  display.value = false;
  store.FecharModalDividendos();
};
</script>
