<template>
  <DataTable
    :value="items"
    dataKey="ticker"
    class="p-datatable-sm mb-5"
    stripedRows
    ref="datatable"
    :loading="state.ocupado"
    v-model:expandedRows="state.expandedRows"
  >
    <Column :expander="true" headerStyle="width: 3rem" />
    <Column field="ticker" header="Ticker" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ data.ticker }}
        </div>
      </template>
    </Column>
    <Column field="cotacao" header="Cotação" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatCurrency(data.cotacao) }}
        </div>
      </template>
    </Column>

    <Column field="pvp" header="P/VP" :sortable="true">
      <template #body="{ data }">
        <div class="text-center small" :class="PvpBackColor(data.pvp)">
          {{ $formatPercent(data.pvp) }}
        </div>
      </template>
    </Column>

    <Column field="valorPatrimonial" header="VP">
      <template #body="{ data }">
        <div class="text-center small">
          {{ $formatCurrency(data.valorPatrimonial) }}
        </div>
      </template>
    </Column>

    <Column field="dy1" header="Últ. Rend" :sortable="true">
      <template #body="{ data }">
        <div class="text-center hand small" @click="AbrirModalDividendos(data.ticker)">
          {{ $formatCurrency(data.ultimoRendimento) }} | {{ $formatPercent(data.dy1) }} %
        </div>
      </template>
    </Column>

    <Column field="dy12" header="DY 12 Meses" :sortable="true">
      <template #body="{ data }">
        <div class="text-center hand"
          :class="Dy12Backcolor(data.dy12)"
          @click="AbrirModalDividendos(data.ticker)"
        >
          {{ $formatPercent(data.dy12) }} %
        </div>
      </template>
    </Column>

    <Column field="Quantidade" header="Qtd" :sortable="true">
      <template #body="{ data }">
        <input 
          type="Number"
          :id="`id-${data.ticker}`"
          class="form-control form-control-sm"
          v-model="data.quantidade"
          @input='AtualizarQuantidade(data)'
        />
        <!-- <b-form-input :id="`id-${data.item.ticker}`" style="max-width: 85px;" @update='ChangeQuantity(data.item.ticker, data.item.quantity)' v-model='data.item.quantity' size="sm" type="number"></b-form-input> -->
      </template>
    </Column>

    <Column field="valor" header="Valor" :sortable="true">
      <template #body="{ data }">
        <div class="text-end">
          {{ $formatCurrency(data.quantidade * data.cotacao) }}
        </div>
      </template>
    </Column>    

    <Column field="tags" header="Div Mensal">
      <template #body="{ data }">
        <div class="text-end">
          {{ $formatCurrency(data.quantidade * data.ultimoRendimento) }}
        </div>
      </template>
    </Column>

    <Column field="tags" header="Remover">
      <template #body="{ data }">
        <div class="text-center hand">        
          <i class="pi pi-trash" style="font-size: 1.1rem" @click="Remover(data)"></i>
        </div>
      </template>
    </Column>    

    <template #expansion="props">
      <div class="orders-subtable">
        <span
          class="badge badge-tags me-1"
          v-for="tag in SplitTags(props.data.tags)"
          :key="tag"
        >
          {{ tag }}
        </span>
      </div>
    </template>
    <template #footer>
      <div>
        <p class="mb-1">Total investido: {{ $formatCurrency(totalInvestido) }}</p>
        <p class="mb-1">Dividendos Mensal {{ $formatCurrency(dividendosMensal) }}</p>
        <p class="mb-1">DY Mensal {{ $formatPercent(dyMensal) }} %</p>
      </div>
    </template>

    <template #empty>
      <div class="p-5 text-center bd-highlight">
        <p class="fs-5" style="text-transform: uppercase">
          Nenhuma informação encontrada
        </p>
      </div>
    </template>
  </DataTable>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
//import RankingService from "@/services/RankingService.js";
import { useMainStore } from '@/store/index.js';
import { Dy12Backcolor, PvpBackColor, SplitTags } from "@/utils/CalcFiis";
import Swal from 'sweetalert2'

const store = useMainStore();
//const service = ref();
const datatable = ref();

const state = reactive({
  ocupado: true,
  ticker: null,
  exibirModalDividendos: false,
  items: [],
  expandedRows: []
});

const items = computed(() => {
  return store.minhaLista;
});

onMounted(async () => {
  // try {
  //   service.value = new RankingService();
  //   await service.value.getRanking();
  // } catch (error) {
  //   console.log(error);
  // } finally {
  state.ocupado = false;
  // }
});

function AbrirModalDividendos (item) {
  store.AbrirModalDividendos(item)
}

function AtualizarQuantidade(item) {
  store.AtualizarQuantidadeNaMinhaLista({ ticker: item.ticker, quantidade: item.quantidade });
}

function Remover(item) {
  Swal.fire({
    title: 'Tem certeza?',
    text: "Deseja remover o item da sua lista?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sim, remover!'
  }).then((result) => {
    if (result.isConfirmed) {
      HandleRemover(item)
    }
  })
}

async function HandleRemover (symbol) {
  store.RemoverDaMinhaLista(symbol.ticker)
}

const totalInvestido = computed(() => {
  try {
    var tmp = items.value.map(x => Number(x.quantidade * x.cotacao))
    const reducer = (accumulator, curr) => accumulator + curr
    return tmp.reduce(reducer, 0)
  } catch {
    return 0
  }
})

const dividendosMensal = computed(() => {
  try {
    var tmp = items.value.map(x => Number((x.quantidade * x.cotacao) * x.dy12) /100)
    const reducer = (accumulator, curr) => accumulator + curr
    var totalInvestido = tmp.reduce(reducer, 0)
    return totalInvestido / 12;
  } catch {
    return 0
  }
})

const dyMensal = computed(() => {
  try {
    let dy = (dividendosMensal.value / totalInvestido.value) * 100
    return dy;
  } catch {
    return 0
  }
})

</script>

<style>
span.badge-tags {
  color: black;
  background-color: lightgray;
}
.orders-subtable {
  padding: 1rem;
}
</style>
