import { useMainStore } from '@/store/index.js';

import http from '@/http.js';

export default class RankingService {
  async getRanking() {
    const store = useMainStore();    
    return new Promise((resolve, reject) => {
      http.get('/api/ranking/fiis')
        .then(response => {
          store.SetFiis(response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          store.SetOnline(false);
          reject(error);
        });
   })
  }
}