<template>
  <nav class="navbar shadow-sm main-nav navbar-light fixed-top">
    <div class="navbar-item me-auto"><span>rankingfii.com.br</span></div>
    <ul class="navbar-nav mx-auto">
      <li class="form-inline">
        <form class="form-inline">
          <div role="group" class="input-group input-group-sm">
            <input
              id="filter-input"
              type="search"
              v-model="search"
              autocomplete="off"
              placeholder="Buscar..."
              class="form-control"
              @input="UpdateMessage"
            />
          </div>
        </form>
      </li>
    </ul>
    <div class="navbar-item ms-auto d-none d-md-block">
      <span>{{ user }}</span>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useMainStore } from '@/store/index.js';

const store = useMainStore();
const search = ref("");

onMounted(() => {});

const user = computed(() => {
  return name.value === null ? "rankingfii.com.br" : name.value;
});

const name = computed(() => {
  return localStorage.getItem("name");
});

const UpdateMessage = () => {
  store.UpdateFilter(search.value);
};
</script>

<style>
.navbar .form-control {
  width: 100% !important;
  height: 38px;
  font-weight: 600 !important;
  padding: 0.4375rem 1rem !important;
  text-transform: uppercase;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  border-radius: 0rem !important;
}
</style>
