import moment from 'moment-timezone'

/**
 * Format value to currency
 * @param value {decimal}
 * @return {string}
 */
export function FormatCurrency (value, currency) {
  if (!value) { value = 0 }
  if (currency === 'USD') {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

/**
 * Format value to Date BR
 * @param value {date}
 * @return {string}
 */
export function FormatDate (date) {
  if (date) {
    return moment(date).format('DD/MM/YY')
  }
}

/**
 * Format value to DateTime BR
 * @param value {date}
 * @return {string}
 */
export function FormatDateTime (date) {
  try 
  {
    return moment(date).format('DD/MM/YY HH:mm')
  } 
  catch(e)
  {
    return ''
  } 
}

/**
 * Format value to Percent
 * @param value {decimal}
 * @return {string}
 */
export function FormatPercent (value, precision) {
  if (!value) { value = 0 }
  if (!precision) { precision = 2 }
  return value.toFixed(precision)
}