<template>
  <section class="bloco-news mb-3" v-if="!subscribed">
    <div class="row">
      <div class="col-xs-12">
        <p class="destaque">Gostou do nosso Ranking?</p>
        <p>
          Inscreva seu email na nossa lista para receber atualizações com as últimas
          novidades do nosso Ranking.
        </p>
      </div>
    </div>
    <div class="control-message">
      <div class="mb-3">
        <label for="formGroupNameInput" class="form-label">Nome</label>
        <input
          type="text"
          v-model="form.name"
          class="form-control"
          id="formGroupNameInput"
          placeholder="Insira seu nome"
        />
      </div>
      <div class="mb-3">
        <label for="formGroupEmailInput" class="form-label">Email</label>
        <input
          type="email"
          v-model="form.email"
          class="form-control"
          id="formGroupEmailInput"
          placeholder="Insira seu email"
        />
      </div>
      <button
        type="button"
        :disabled="!ready"
        class="btn btn-success mt-2"
        @click="Enviar"
      >
        Inscrever-se
      </button>
    </div>
  </section>

  <div class="alert alert-success" role="alert" v-else>Obrigado pela inscrição</div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import http from "@/http.js";

const ocupado = ref(true);
const form = ref({
  name: null,
  email: null,
});
const name = ref(null);
const email = ref(null);

onMounted(async () => {
  email.value = localStorage.getItem("email");
  name.value = localStorage.getItem("name");
  ocupado.value = false;
});

const subscribed = computed(() => {
  return email.value !== null;
});

const ready = computed(() => {
  return form.value.email !== null && form.value.name !== null;
});

const Enviar = (event) => {
  event.preventDefault();
  if (!ready.value) {
    return;
  }

  http
    .post("/api/subscribed", form.value)
    .then(() => {
      localStorage.setItem("email", form.value.email);
      localStorage.setItem("name", form.value.name);
      email.value = form.value.email;
      name.value = form.value.name;
      form.value.email = null;
      form.value.name = null;
    })
    .catch((e) => {
      console.error(e);
      alert("Ocorreu um erro ao efetuar a inscrição");
    });
};
</script>

<style scoped>
.bloco-news {
  background-color: rgba(34, 34, 34, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-size: 0.875rem;
  max-width: 660px;
  margin: 20px auto 0;
  padding: 20px;
}
.bloco-news p.destaque {
  line-height: 1.3;
  font-weight: 700;
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 5px;
}
</style>
