import { useMainStore } from '@/store'

export function Dy12Backcolor (value) {

  const store = useMainStore()

  let selic = store.selic

  if (value < 1) {
    return "bg-secondary"
  }
  if (value < selic * 0.9) {
    return "bg-warning";
  }
  if (value >= selic * 0.9 && value <= selic * 1.1) {
    return "bg-info";
  }
  if (value > selic * 1.1) {
    return "bg-success";
  }
  return "bg-secondary";
}

export function CalcularPatrimonio (value) {
  var suf = "mi";
  var t1 = value / 1000 / 1000;
  if (t1 > 1000) {
    t1 = t1 / 1000;
    suf = "bi";
  }
  return `R$ ${Precise(t1)} ${suf}`.replace(".", ",");
}

export function Milhar (value) {
  var sRegExp = new RegExp("(-?[0-9]+)([0-9]{3})"),
    sValue = value + "";
  sValue = sValue.replace(sRegExp, "$1.$2");
  return sValue;
}

export function Precise (num) {
  return Number.parseFloat(num).toPrecision(4);
}

export function SplitTags (tags) {
  if (!tags) {
    return "";
  }
  var arrayOfStrings = tags.trim().split(";");
  return arrayOfStrings;
}

export function PvpBackColor (value) {
  try {
    if (value <= 0.0) {
      return "bg-secondary";
    }
    if (value < 0.9) {
      return "bg-success";
    }
    if (value >= 0.9 && value <= 1) {
      return "bg-info";
    }
    if (value > 1 && value <= 1.1) {
      return "bg-warning";
    }
    if (value > 1.1) {
      return "bg-danger";
    }
  } catch (error) {
    return "bt-secondary";
  }
}