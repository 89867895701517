import http from '@/HttpTrade.js'

export default class TradeService {
  async getDividends(ticker) {
    return new Promise((resolve, reject) => {   
      http.get(`/dividends/${ticker}/announced`).then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    })
  }

  async getInfo(ticker) {
    return new Promise((resolve, reject) => {   
      http.get(`/company/${ticker}/symbol`).then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    })
  }  
}