<template>
  <DataTable
    :value="dividends"
    class="p-datatable-sm"
    stripedRows
    :lazy="true"
    ref="datatable"
    :loading="state.ocupado"
    v-if="dividends"
  >
    <Column field="recordDate" header="Data Com" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatDate(data.recordDate) }}
        </div>
      </template>
    </Column>
    <Column field="paymentDate" header="Pagamento" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatDate(data.paymentDate) }}
        </div>
      </template>
    </Column>
    <Column field="value" header="Valor" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ $formatCurrency(data.value) }}
        </div>
      </template>
    </Column>
    <Column field="progress" header="Evolução">
      <template #body="{ data }">
        <div class="small">
          <ProgressBar :value="CalcPercent(data.value)" :show-value="false"></ProgressBar>
        </div>
      </template>
    </Column>
    <Column field="type" header="Tipo" :sortable="true">
      <template #body="{ data }">
        <div class="small">
          {{ data.type }}
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import { reactive, onMounted, defineProps } from "vue";
import { computed } from "vue";
import ProgressBar from "primevue/progressbar";

const props = defineProps({
  dividends: {
    type: Object,
  },
  maxDividendo: {
    type: Number,
  },
});

const dividends = computed(() => props.dividends);
const maxDividendo = computed(() => props.maxDividendo);

const state = reactive({
  ocupado: true,
  maxDiv: 0,
});

onMounted(async () => {
  state.ocupado = false;
});

function CalcPercent(value) {
  return (value * 100) / maxDividendo.value;
}
</script>
