import { defineStore } from 'pinia'

export const useMainStore = defineStore({
  id: 'main',
  state: () => ({
    _fiis: [],
    _selic: 13.25,
    _filter: null,
    _minhaLista: [],
    _exibirModalDividendos: false,
    _tickerSelecionado: null,
    _online: true
  }),
  getters: {
    fiis: (state) => state._fiis,
    filter: (state) => state._filter,
    selic: (state) => state._selic,
    minhaLista: (state) => state._minhaLista,
    exibirModalDividendos: (state) => state._exibirModalDividendos,
    tickerSelecionado: (state) => state._tickerSelecionado,
    online: (state) => state._online
  },
  actions: {
    AtualizarMinhaLista(ticker) {
      ticker = ticker.toUpperCase()
      let x = this._minhaLista.map(x => x.ticker).indexOf(ticker)
      if (x >= 0) { return }
      
      let i = this._fiis.map(x => x.ticker).indexOf(ticker)
      if (i >= 0) {
        var item = this._fiis[i]
        if (!item.quantidade) { item.quantidade = 0 }
        this._minhaLista.push(item)
        localStorage.setItem('myList', JSON.stringify(this._minhaLista))
      }
    },
    AtualizarQuantidadeNaMinhaLista(data) {
      let i = this._minhaLista.map(x => x.ticker).indexOf(data.ticker)
      if (i >= 0) {
        var tmp = this._minhaLista[i] 
        tmp.quantidade = Number(data.quantidade)
        this._minhaLista[i] = tmp
      }
      localStorage.setItem('myList', JSON.stringify(this._minhaLista))
    },
    RemoverDaMinhaLista (ticker) {
      let i = this._minhaLista.map(x => x.ticker).indexOf(ticker)
      if (i >= 0) { 
        this._minhaLista.splice(i, 1)
      }
      localStorage.setItem('myList', JSON.stringify(this._minhaLista))      
    },
    AbrirModalDividendos (ticker) {
      this._tickerSelecionado = ticker
      this._exibirModalDividendos = true
    },
    FecharModalDividendos () {
      this._exibirModalDividendos = false
    },
    SetFiis (fiis) {
      this._fiis = fiis
      
      let minhaLista = localStorage.getItem('myList')
      if (minhaLista) {
        minhaLista = JSON.parse(minhaLista)
        minhaLista.forEach((item) => {
          this.AtualizarMinhaLista(item.ticker)
          this.AtualizarQuantidadeNaMinhaLista({ ticker: item.ticker, quantidade: item.quantidade })
        });        
      }      
    },
    UpdateFilter (filter) {
      this._filter = filter
    },    
    SetOnline(online)     {
      this._online = online
    }
  }
})
