<template>
  <div class="row">
    <div class="col mb-2 mt-2">
      <Button
        label="Adicionar FII"
        icon="pi pi-plus"
        iconPos="right"
        @click="AdicionarFII"
        class="p-button-sm"
      />
    </div>
  </div>
  <TabelaMinhaLista></TabelaMinhaLista>
  <div style="max-width: 300px">
    <Chart type="pie" :data="chartData" :options="lightOptions" />
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import TabelaMinhaLista from "@/components/TabelaMinhaLista.vue";
import Swal from "sweetalert2";
import { useMainStore } from "@/store/index.js";
import Chart from "primevue/chart";

const state = reactive({ ocupado: true });
const store = useMainStore();

onMounted(async () => {
  state.ocupado = false;
});

async function AdicionarFII() {
  const { value: symbol } = await Swal.fire({
    title: "Adicionar FII a Minha Lista",
    input: "text",
    inputPlaceholder: "Informe o FII",
  });

  if (symbol) {
    HandleAdicionar(symbol);
  }
}

function HandleAdicionar(symbol) {
  store.AtualizarMinhaLista(symbol);
}

const chartData = {
  labels: ["A", "B", "C"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
      hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
    },
  ],
};
const lightOptions = {
  plugins: {
    legend: {
      labels: {
        color: "#495057",
      },
    },
  },
};
</script>

<style lang="css" scoped></style>
