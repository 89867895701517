import axios from 'axios'

const baseApiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5501/api' : 'https://trade.algoplus.com.br/api'

axios.defaults.headers.common["api-key"] = "621AEC7F-D305-4A91-8846-B8B720785C33";
//axios.defaults.headers.common["api-key"] = "96011AC1-B09B-4649-9880-F431620B3EC0";

let http = axios.create({
  responseType: "json",
  baseURL: baseApiUrl
});

export default http;
